<template>
  <OtherFunctionEditView disabled />
</template>

<script>
import OtherFunctionEditView from '@/views/otherFunction/OtherFunctionEditView'

export default {
  name: 'OtherFunctionView',
  components: {
    OtherFunctionEditView
  }
}
</script>
