<script>
import OtherFunctionNewView from '@/views/otherFunction/OtherFunctionNewView'

export default {
  name: 'OtherFunctionEditView',
  extends: OtherFunctionNewView,
  created () {
    this.getOtherFunction()
  }
}
</script>
